import React from 'react';
import { ReactComponent as TrainingIcon } from '../../../assets/training.svg';
import { ReactComponent as YogaIcon } from '../../../assets/yoga.svg';
import { ReactComponent as PilatesIcon } from '../../../assets/pilates.svg';
import { ReactComponent as StrengthIcon } from '../../../assets/strength.svg';
import { ReactComponent as WellbeingIcon } from '../../../assets/wellbeing.svg';
import { ReactComponent as PowerliftIcon } from '../../../assets/powerlifting.svg';
import { ReactComponent as OtherIcon } from '../../../assets/other.svg';
// import { ReactComponent as AllIcon } from '../../../assets/shortlogo.svg';
import { ReactComponent as AllIcon } from '../../../assets/fleggsLogoBlackSvg.svg';
import { cloneDeep } from 'lodash';
import css from './ServicesNames.module.css';
import classNames from 'classnames';

const getOptions = options => {
  const finalOptions = cloneDeep(options);
  const hasAllOption = options.some(option => option.option === 'all');
  if (!hasAllOption) {
    finalOptions.unshift({ label: 'All', option: 'all' });
  }
  for (let option of finalOptions) {
    if (option.option === 'all') {
      option.Icon = AllIcon;
    } else if (option.option === 'training') {
      option.Icon = TrainingIcon;
    } else if (option.option === 'yoga') {
      option.Icon = YogaIcon;
    } else if (option.option === 'pilates') {
      option.Icon = PilatesIcon;
    } else if (option.option === 'strength') {
      option.Icon = StrengthIcon;
    } else if (option.option === 'wellbeing') {
      option.Icon = WellbeingIcon;
    } else if (option.option === 'powerlifting') {
      option.Icon = PowerliftIcon;
    } else if (option.option === 'other') {
      option.Icon = OtherIcon;
    }
  }
  return finalOptions;
};

function ServicesNames(props) {
  const { config, onSelect, initialValues } = props;
  const queryName = 'pub_' + config.key;
  const options = getOptions(config.enumOptions);
  const selectValue = initialValues[queryName];
  const [formatedValue] =
    selectValue && typeof selectValue === 'string'
      ? selectValue.replace(/(has_any:|has_all:)/, '').split(',')
      : ['all'];

  const handleClick = (e, option) => {
    e.preventDefault();
    const queryParams = {
      [queryName]: option.option === 'all' ? null : `has_any:${option.option},${option.label}`,
    };
    onSelect(queryParams);
  };

  return (
    <div className={css.root}>
      <ul className={css.serviceNames}>
        {options.map(option => (
          <li
            key={option.option}
            className={classNames(css.serviceName, css[option.option], {
              [css.selectedOption]: option.option === formatedValue,
            })}
          >
            <button type="button" onClick={e => handleClick(e, option)}>
              <option.Icon />
              <span className={css.text}>{option.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ServicesNames;

import React, { Component, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FieldDateInput, FieldSelect, Form } from '../../../components';

import { FormattedMessage, injectIntl } from 'react-intl';
import { useConfiguration } from '../../../context/configurationContext';
import { stringifyDateToISO8601, timeOfDayFromTimeZoneToLocal } from '../../../util/dates';
import moment from 'moment';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import css from './BookingDateTimeFilter.module.css';
import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import classNames from 'classnames';

const times = [...new Array(25)];
const timesArr = times.map((_, indx) => ({
  value: indx,
  label: `${indx < 10 ? '0' : ''}${indx}:00`,
}));
const startTimesArr = timesArr.filter((_, indx) => indx !== timesArr.length - 1);
const endTimesArr = timesArr.filter((_, indx) => indx > 0);

const getDatesQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'dates';
};

// Parse query parameter, which should look like "2020-05-28,2020-05-31"
const parseValue = value => {
  const rawValuesFromParams = value ? value.split(',') : [];
  const [startDate, endDate] = rawValuesFromParams;
  const date = moment(startDate).toDate();
  const startTime = moment(startDate).format('H');
  const endTime = moment(endDate).format('k');
  return (
    value &&
    date &&
    startTime &&
    endTime && { date: { date }, bookingStartTime: startTime, bookingEndTime: endTime }
  );
};
// Format dateRange value for the query.

const formatValue = (dateRange, queryParamName) => {
  const hasDates = dateRange && dateRange.dates;
  const { startDate, endDate } = hasDates ? dateRange.dates : {};
  const start = startDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss') : null;
  const end = endDate ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss') : null;
  const value = start && end ? `${start},${end}` : null;
  return { [queryParamName]: value };
};

const getCurrentHour = date => {
  if (!date) {
    return '0';
  }
  const today = moment().format('YYYY-MM-DD');
  const dateStr = moment(date).format('YYYY-MM-DD');
  const isToday = dateStr === today;
  const currentHour = isToday ? moment().format('H') : '0';
  return currentHour;
};

function BookingDateTimeFilterComponent(props) {
  const { initialValues, ...rest } = props;
  const config = useConfiguration();
  const [showDate, setShowDate] = useState(false);
  const parsedInitialValues = parseValue(initialValues.dates);

  // Outside range -><- today ... today+available days -1 -><- outside range
  const isOutSideRange = day => {
    const endOfRange = config.stripe?.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  };

  const toggleDate = status => setShowDate(status);

  return (
    <FinalForm
      {...rest}
      initialValues={{ BookingDateTimeFilter: { ...parsedInitialValues } }}
      toggleDate={toggleDate}
      showDate={showDate}
      render={formRenderProps => {
        const {
          form,
          values,
          id,
          intl,
          queryParamNames,
          initialValues,
          showDate,
          toggleDate,
        } = formRenderProps;
        const { bookingEndTime, bookingStartTime, date } = values.BookingDateTimeFilter || {};
        const datesQueryParamName = getDatesQueryParamName(queryParamNames);
        const formSubmit = e => e.preventDefault();

        const handleDateChange = value => {
          const currentHour = getCurrentHour(value.date);
          form.batch(() => {
            form.change('BookingDateTimeFilter.date', value);
            form.change('BookingDateTimeFilter.bookingStartTime', currentHour);
            form.change('BookingDateTimeFilter.bookingEndTime', '24');
          });
          toggleDate(false);
        };

        const handleFormSpyChange = formValues => {
          const { values } = formValues;
          const { BookingDateTimeFilter } = values;
          const { BookingDateTimeFilter: initalValue } = initialValues;
          const { date, bookingEndTime, bookingStartTime } = BookingDateTimeFilter || {};
          const { date: initDate, bookingEndTime: initEndTime, bookingStartTime: initStartTime } =
            initalValue || {};
          const hasRequiredValues =
            date &&
            date.date &&
            date.date instanceof Date &&
            !!bookingEndTime &&
            !!bookingStartTime &&
            (bookingStartTime !== initStartTime ||
              bookingEndTime !== initEndTime ||
              date.date.toISOString() !== initDate?.date?.toISOString());

          if (hasRequiredValues) {
            const startDate = moment(date.date)
              .startOf('date')
              .add(bookingStartTime, 'hours')
              .toDate();
            const endDate = moment(date.date)
              .startOf('date')
              .add(bookingEndTime, 'hours')
              .toDate();
            const formatedValue = formatValue(
              { dates: { startDate, endDate } },
              datesQueryParamName
            );

            props.onSubmit(formatedValue);
          }
        };

        const onClearHandler = e => {
          e.preventDefault();
          form.reset();
          toggleDate(false);
          props.onSubmit({ [datesQueryParamName]: undefined });
        };

        const calenderShowBtnClick = e => {
          e.preventDefault();
          toggleDate(true);
        };

        const closeCalender = e => {
          e.preventDefault();
          toggleDate(false);
        };

        const handleOutsideClick = e => {
          if (e.target && e.target.id !== 'BookingDateTimeFilter.clearBtn') {
            toggleDate(false);
          }
        };
        const startTime = parseInt(
          bookingStartTime ? bookingStartTime : getCurrentHour(date?.date)
        );
        const endTime = parseInt(bookingEndTime ? bookingEndTime : '24');
        const isValidDate = date && date.date instanceof Date;

        return (
          <Form onSubmit={formSubmit} className={css.form}>
            <FormSpy subscription={{ values: true }} onChange={handleFormSpyChange} />
            <div className={css.datesWrapper}>
              <div className={css.date}>
                <div className={css.dateBtnTitle}>
                  <FormattedMessage id="BookingDateTimeFilter.dateLabel" />
                </div>
                <button
                  className={classNames(css.dateBtn, {
                    [css.placeholderDateBtn]: !isValidDate,
                  })}
                  type="button"
                  onClick={calenderShowBtnClick}
                >
                  {isValidDate ? moment(date.date).format('MM.DD.YYYY') : 'MM.DD.YYYY'}
                </button>
                {showDate ? (
                  <div className={css.fieldDateInput}>
                    <DayPickerSingleDateController
                      numberOfMonths={1}
                      onDateChange={date => handleDateChange({ date: date.toDate() })}
                      date={date && date.date ? moment(date.date) : null}
                      isDayBlocked={isOutSideRange}
                      onBlur={closeCalender}
                      onOutsideClick={handleOutsideClick}
                      isFocused={true}
                      navNext={<NextMonthIcon />}
                      navPrev={<PreviousMonthIcon />}
                      firstDayOfWeek={1}
                    />
                    <div className={css.calenderBtn}>
                      <button
                        type="button"
                        onClick={onClearHandler}
                        className={css.clearBtn}
                        id="BookingDateTimeFilter.clearBtn"
                      >
                        <FormattedMessage id="BookingDateTimeFilter.clearBtn" />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className={css.selectWrapper}>
                <FieldSelect
                  name={'BookingDateTimeFilter.bookingStartTime'}
                  id={'BookingDateTimeFilter.bookingStartTime'}
                  className={css.fieldSelect}
                  label={intl.formatMessage({ id: 'FieldDateAndTimeInput.startTime' })}
                  disabled={!(date && date.date)}
                >
                  {startTimesArr
                    .filter(time => time.value >= startTime)
                    .filter(time => time.value < endTime)
                    .map(time => (
                      <option value={time.value} key={time.value}>
                        {time.label}
                      </option>
                    ))}
                </FieldSelect>

                {/* <div className={css.lineBetween}>-</div> */}

                {/* <FieldSelect
                  name={'BookingDateTimeFilter.bookingEndTime'}
                  id={'BookingDateTimeFilter.bookingEndTime'}
                  className={css.fieldSelect}
                  label={intl.formatMessage({ id: 'FieldDateAndTimeInput.endTime' })}
                  disabled={!(date && date.date)}
                >
                  {endTimesArr
                    .filter(time => time.value > startTime)
                    .map(time => (
                      <option value={time.value} key={time.value}>
                        {time.label}
                      </option>
                    ))}
                </FieldSelect> */}
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

const BookingDateTimeFilter = injectIntl(BookingDateTimeFilterComponent);

export default BookingDateTimeFilter;
